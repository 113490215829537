import * as XLSX from 'xlsx/xlsx.mjs';

function exportExcel (data,datestart,dateFinish) {
    let dataJson = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    const filename = "reportes";
    XLSX.utils.book_append_sheet(workbook, dataJson, filename);
    XLSX.writeFile(workbook, `${datestart} a ${dateFinish}.xlsx`);
};

export default   { exportExcel }