<template>
  <Loading :isLoading="isLoading" />
    <div>
         <div class="w-full">
            <h2 class="text-center font-bold" style="color:#610032">
                Reporte de Prenómina
            </h2>
        </div>
        <div class="w-full mt-10">
            <div class="sm:w-1/2 text-3xl">
                <div style="color:#610032" id="label_sales_ranges_capture_period_DE">Periodo <label id="label_period_capture_period"> {{namePeriod.sale_commission_payment_period.name}}</label></div>
            </div>
        </div>
        <div class="w-full">
            <div class="sm:w-1/2 text-3xl">
                <div style="color:#610032" id="label_sales_ranges_capture_period_DE">Asesor: <label id="label_adviser_capture_period"> {{fullName}}</label></div>
            </div>
        </div>
       <div class="mt-5">
            <table class="width200">
                <thead>
                    <tr>
                        <th>RFC</th>
                        <th>Nombre Completo del Socio</th>
                        <th>Fecha Registro</th>
                        <th>Fecha Registro de Comisión</th>
                        <th>Producto</th>
                        <th>Comisión</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data,index) of _reportsResponse.commission_list" :key="index" :style="[(index%2==0)? {'background': '#FFF'}:{'background': '#efefef' }]">
                        <td> <label :id="'label_adviser_rfc_capture_period_'+index">{{data.client_info.rfc}} </label></td>
                        <td> <label :id="'label_partners_full_name_capture_period_'+index"> 
                            {{data.client_info.paternal_name}} {{data.client_info.maternal_name}} {{data.client_info.name}} {{data.client_info.middle_name}}</label>
                        </td>
                       <td> <label :id="'label_registration_date_capture_period_'+index">
                            {{$filters.formatDate(data.client_info.date_of_registration_of_admission_request)}}
                           </label>
                       </td>
                        <td> <label :id="'label_commission_registration_date_capture_period_'+index">
                            {{$filters.formatDate(data.created_at)}}
                             </label>
                        </td>
                      <td> <label :id="'label_product_capture_period_'+index">
                           {{data.product_name}} </label>
                        </td>
                         <td> <label :id="'label_comission_capture_period_'+index">{{$filters.formatCurrency(data.amount)}} </label></td>
                         <td> <button :id="'icon_delete_capture_period_'+index"  style="outline:none" @click="deactivateCommission(data)"><img class="btn_delete" height="25" width="25" src="@/assets/eliminar.svg"></button></td>
                    </tr>
                </tbody>
            </table>
            <div style="background:#efefef; border: 1px solid #810042;" class="w-full p-5  text-right">
                 <label class="mr-10" id="label_comission_total_capture_period" > Total de comisiones: {{$filters.formatCurrency(total_amount_to_pay)}} </label>
            </div>
           
       </div>

         <div class="mt-5 lg:text-right">
            <button id="button_export_report_capture_period" class="px-20 py-5 w-full lg:mx-10 lg:w-1/4" @click="exportToexcel()">Exportar Reporte</button>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue-demi';
import CommissionScheme from "@/classes/CommissionScheme.js";
import Swal from 'sweetalert2';
import Loading from '@/components/Loading/VueLoading.vue';
import { useRouter } from 'vue-router';
import Excel from "@/helpers/excel";
import { formatDate } from "@/helpers/filters";

export default {
    name:"ReportsPayroll",
    components:{
        Loading
    },
    setup() {
        let _reportsResponse=ref([]);
        let isLoading=ref(false);
        let fullName=ref();
        let namePeriod=ref({
            has_error: "",
            message: "",
            sale_commission_payment_period:{}
        });
        let total_amount_to_pay=ref();
        const router = useRouter();

        onMounted(async ()=>{
            await getNextPeriod();
            getCommissionOnAssociate(router.currentRoute._value.params.id);
        });

        async function getCommissionOnAssociate(urlId){
            isLoading.value=true;
            try {
                let _commisionScheme=new CommissionScheme();
                let _response=await _commisionScheme.get({
                    "token_auth":sessionStorage.getItem("login"),
                    "start_date":namePeriod.value.sale_commission_payment_period.start_date,
                    "end_date": namePeriod.value.sale_commission_payment_period.end_date,
                    "users_id":urlId
                },"foncabsa/prepayroll_report/get_detail_by_associate");
                if(_response.status==200){
                    isLoading.value=false;
                    _reportsResponse.value=_response.data.response;
                    fullName.value=`${_reportsResponse.value.associate_name.paternal_name} ${_reportsResponse.value.associate_name.maternal_name} ${_reportsResponse.value.associate_name.middle_name}  ${_reportsResponse.value.associate_name.name}`
                    total_amount_to_pay.value=_reportsResponse.value.total_amount_to_pay;
                }
                if(_response.data.response.has_errors){
                    let messages="";
                    isLoading.value=false;
                    _response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
                }
                
                
            } catch (error) {
                console.log(error)
                isLoading.value=false;
                Swal.fire({
                    title: "Error en el servidor ",
                    // html:messages,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
            }

        }

        function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
        }

        async function getNextPeriod(){
            isLoading.value=true;
            try {
                let _commisionScheme=new CommissionScheme();
                let _response=await _commisionScheme.get({ "token_auth":sessionStorage.getItem("login") },"foncabsa/sale_commission_payment_period/get_next_period");
                if(_response.status==200){
                    isLoading.value=false;
                    namePeriod.value=_response.data.response;
                }
                if(_response.data.response.has_errors){
                    let messages="";
                    isLoading.value=false;
                    _response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>";
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
                }
                
            } catch (error) {
                console.log(error)
                isLoading.value=false;
                Swal.fire({
                    title: "Error en el servidor ",
                    // html:messages,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
            }
        }

        async function deactivateCommission(dataElement){
            Swal.fire({
                icon:"warning",
                title:"Cuidado!",
                text:"¿Desea remover esta comisión?",
                reverseButtons:false,
                showCancelButton: true,
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: '#FEB72B',
                cancelButtonColor: '#FFFFFF',
            }).then(async (result) => {
                if (result.value) {
                    remuveCommission(dataElement);
                }
            })
            setIdAttributeToAccepAndCancelButtonSwalFire('button_delete_confirmation_capture_period', 'cancel_delete_confirmation_capture_period');
        }

        async function remuveCommission(dataElement){
            isLoading.value=true;
            try {
                let _commisionScheme=new CommissionScheme();
                let _response=await _commisionScheme.save({ token_auth:sessionStorage.getItem("login"),sale_commission_id:dataElement.id },`/${process.env.VUE_APP_BUSINESS_FONCABSA}/prepayroll_report/deactivate`);
                if(_response.status==200){
                    getCommissionOnAssociate(router.currentRoute._value.params.id);
                    isLoading.value=false;
                    Swal.fire({
                        title: "Proceso ejecutado",
                        text:"Elemento removido exitosamente.",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
                }
                if(_response.data.response.has_errors){
                    isLoading.value=false;
                    let messages="";
                    isLoading.value=false;
                    _response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>";
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
                }       
            } catch (error) {
                console.log(error)
                isLoading.value=false;
                Swal.fire({
                    title: "Error en el servidor ",
                    // html:messages,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
            }
        }

        function exportToexcel(){
            let _dataExportExcel=[];
            _reportsResponse.value.commission_list.forEach(element=>{
                _dataExportExcel.push({
                    rfc:element.client_info.rfc,
                    nombre_completo_del_socio:`${element.client_info.paternal_name} ${element.client_info.maternal_name} ${element.client_info.name} ${element.client_info.middle_name}`,
                    fecha:formatDate(element.created_at),
                    producto:element.product_name,
                    comision:element.amount,
                })
            });
            Excel.exportExcel(_dataExportExcel,namePeriod.value.sale_commission_payment_period.start_date,namePeriod.value.sale_commission_payment_period.end_date);
            Swal.fire({
                icon:"success",
                title:"Reporte generado.",
                text:"El reporte de prenómina ha sido generado con éxito.",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
            });
            setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_message','');
        }

        return { _reportsResponse,isLoading,fullName,total_amount_to_pay,namePeriod,deactivateCommission,exportToexcel}
    }
}
</script>

<style>
#cancel_delete_confirmation_capture_period{
    color: #810042;
    border: 1px solid #810042;
    transition: all 300ms;
}

#cancel_delete_confirmation_capture_period:hover{
    color: white;
    border: 1px solid #810042;
    background-color:#810042 !important;
}
</style>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: center;
  padding: 10px  ;
}

tr:nth-child(even){background-color: #ffffff;}

th {
  background-color: #ffffff;
}

table.width200,table.rwd_auto {border:1px solid #810042;border-radius: 10px;width:100%;margin:0 0 50px 0}
    .width200 th,.rwd_auto th {background:#ffffff;text-align:center;padding-top: 20px;padding-bottom: 20px;}
    .width200 tr:last-child td, .rwd_auto tr:last-child td{border:50px}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.width200 tbody tr { height: 70px;}

.width200 thead {border-bottom: 1px solid #810042;}


@media only screen and (max-width: 1024px)
{
    table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }

    .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }

    .width200 tr th:nth-child(5){display: none;}

    .width200 tbody tr { height: auto;}

    .width200 td { border: none;border-bottom: 1px solid #810042; position: relative;padding-left: 40% ;text-align:left }

    .width200 td:before {  position: absolute;top: 0px; left: 6px; width: 45%; padding-right: 10px; }

    .width200 thead {border-bottom: 0px solid #810042}


    td:nth-of-type(1):before { content: "RFC:"; }
    td:nth-of-type(2):before { content: "Nombre Completo del Socio"; }
    td:nth-of-type(3):before { content: "Fecha Registro"; }
    td:nth-of-type(4):before { content: "Fecha Registro de Comisión	"; }
    td:nth-of-type(5):before { content: "Producto"; }
    td:nth-of-type(6):before { content: "Comisión"; }

    .descarto {display:none;}
    .fontsize {font-size:10px}

    .width200{
        border-radius: 0px;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px)
{
    .descarto {display:none;}
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
    .descarto {display:none;}
    .fontsize {font-size:10px}
    
}

@media only screen and (min-width: 1024px)
{
    .color-cherry-border {border:1px solid #810042}
}

@media (max-width: 300px) {
  .pagination-items {
    height: 20px;
    width: 30px;
  }
}

#button_export_report_capture_period{
    transition: all 300ms;
    border: 2px solid #810042;
    color: #810042;
    background-color: white;
    border-radius: 5px;
}

#button_export_report_capture_period:hover{
    background-color: #810042;
    color: white;
}

.btn_delete{
    width: 25px;
    height: 25px;
    transition: all 300ms;
}

.btn_delete:hover{
    width: 30px;
    height: 30px;
}
</style>